const Astrology = () => {
    return (
        <>
          <div className='container-fluid'>
                <div className='row m-0'>
            <div className="area-chatbot">
            <div className="context-chatbot">
                <h1>Comeing Soon.......... </h1>
            </div>
            <div className='background-img'>
                    <img className='backgroundImg' src='https://img.freepik.com/premium-photo/zodiac-symbols-sacred-temple-backdrop-astrology-alchemy-magic-sorcery-crystals-enigmatic-structure_285885-3305.jpg'/>
                </div>
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            </div>
        </div>
        </>
    )
}
export default Astrology;