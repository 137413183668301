import './client.css';
const Client = () => {


    return (
        <>
            <div className="column col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="pricing-card basic client-card h-100">
                    <div className="pricing-header">
                        <div className='header-review'>
                            <a className='review'>Review</a>
                        </div>
                        <div className="price-circle"> </div>
                    </div>
                    <div className='client-info mt-5'>
                        <h5>Harry</h5>
                        <p>I love how they made everything easy to understand. Their solutions fit our needs perfectly, and we saw improvements almost immediately.</p>
                    </div>
                </div>
            </div>
            <div className="column col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="pricing-card basic client-card h-100">
                    <div className="pricing-header">
                    <div className='header-review'>
                            <a className='review'>Review</a>
                        </div>
                        <div className="price-circles"></div>
                    </div>
                    <div className='client-info mt-5'>
                        <h5>Nora</h5>
                        <p>The support team is so helpful and friendly. They really go above and beyond to ensure we have everything we need and understand every step.</p>
                    </div>
                </div>
            </div>
            <div className="column col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                <div className="pricing-card basic client-card h-100">
                    <div className="pricing-header">
                    <div className='header-review'>
                            <a  className='review'>Review</a>
                        </div>
                        <div className="price-circl"></div>
                    </div>
                    <div className='client-info mt-5'>
                        <h5>Bella</h5>
                        <p>Their technology is straightforward and effective. It’s definitely improved our workflow and made our processes much more efficient.</p>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Client;