import './account.module.css'
const Loginform = () => {
    return (
        <>
            <div className="container-fluid">
                <div className="conatiner">
                    <div className="row mx-0 justify-content-center">
                        <div className="col-lg-4 login-page">
                            <div className="form">
                                <div className="title login">Login Form</div>
                                <div className="subtitle">Let's create your account!</div>
                                <div className="input-container ic1">
                                    <input id="firstname" className="input" type="text" placeholder=" " />
                                    <div className="cut" />
                                    <label htmlFor="firstname" className="placeholder">First name</label>
                                </div>
                                <div className="input-container ic2">
                                    <input id="lastname" className="input" type="text" placeholder=" " />
                                    <div className="cut" />
                                    <label htmlFor="lastname" className="placeholder">Last name</label>
                                </div>
                                <div className="input-container ic2">
                                    <input id="email" className="input" type="text" placeholder=" " />
                                    <div className="cut cut-short" />
                                    <label htmlFor="email" className="placeholder">Email
                                    </label></div>
                                <button type="text" className="submit">Submit</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Loginform;