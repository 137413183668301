import './gameing.css'
const Gameing = () => {
    return (
        <>
            <div className='container-fluid'>
                <div className='row m-0'>
                    <div className="area-chatbot">
                        <div className="context-chatbot">
                            <h1>Comeing Soon.......... </h1>
                        </div>
                        <div className='background-img'>
                            <img className='backgroundImg' src='https://t4.ftcdn.net/jpg/05/64/31/67/360_F_564316725_zE8llusnCk3Sfr9rdfKya6fV7BQbjfyV.jpg' />
                        </div>
                        <ul className="circles">
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Gameing;