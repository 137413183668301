// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Home from "./pages/home";
import Ecommarce from "./pages/Ecommarce";
import Ottplatform from "./pages/ottplatform/ottplatform";
import Gameing from "./pages/gameing/gameing";
import Chatbot from "./pages/chatbot/chatbot";
import Voiceassistant from "./pages/voiceassistant/voiceassistant";
import Socialmedia from "./pages/socialmedia/socialmedia";
import Purchase from "./pages/account/purchase";
import Loginform from "./pages/account/loginform";
import Astrology from "./pages/astrology/astrology";
function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Ecommarce" element={<Ecommarce />} />
          <Route path="/ottplatform" element={<Ottplatform />} />
          <Route path="/gameing" element={<Gameing />} />
          <Route path="/chatbot" element={<Chatbot />} />
          <Route path="/voiceassistant" element={<Voiceassistant />} />
          <Route path="/socialmedia" element={<Socialmedia />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/loginform" element={<Loginform />} />
          <Route path="/astrology" element={<Astrology />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
