const Ecommarce = () => {
    return (
        <>
             <div className='container-fluid'>
                {/* <div className='container'> */}
                    <div className='row m-0'>
                        <div className="area-chatbot">
                            <div className="context-chatbot">
                                <h1>Comeing Soon.......... </h1>
                            </div>
                            <div className='background-img'>
                                <img className='backgroundImg' src='https://www.undp.org/sites/g/files/zskgke326/files/2023-08/undp-rbec-socialmedia-social-illustration-omer-sayed-2023-edited.jpg' />
                            </div>
                            <ul className="circles">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                {/* </div> */}
            </div>
        </>
    );
}
export default Ecommarce;
