import React from "react";
const Voiceassistant = () => {
    return(
        <>
        <div className='container-fluid'>
            {/* <div className='container'> */}
                <div className='row m-0'>
            <div className="area-chatbot">
            <div className="context-chatbot">
                <h1>Comeing Soon.......... </h1>
            </div>
            <div className='background-img'>
                    <img className='backgroundImg' src='https://t3.ftcdn.net/jpg/04/91/09/72/360_F_491097264_CGuzXDvKPkKBTxUnX0Jg9k5rbinL3Xhv.jpg'/>
                </div>
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
                </div>
            {/* </div> */}
        </div>

        </>
    )
}
export default Voiceassistant;