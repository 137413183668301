import React from 'react';
import SwiperComponent from '../swiper-slider/swiper';
import aboutimg from '../assets/about-me.jpg';
import Client from './client/client';
import Choose from './chooseing/choose';
const Home = () => {
    return (
        <>
            <div className="home-page">
                <div className="container-fulid main-bar">
                    <div className="container">
                        <div className="row m-0 bright-background">
                            <div className="col-md-12 col-lg-6">
                                <div className="row m-0">
                                    <ul className="circles">
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                        <li></li>
                                    </ul>
                                    <div className="Bright-text">
                                        <h5>Improve Your Online Presence with to <a className="bright-website">Our Advanced Solutions</a></h5>
                                        <p className='slide-up'>Learn how we can boost your online presence. Our solutions like e-commerce, streaming, chat services, astrology insights, and more to improve your digital experience and connect you with your audience smoothly.</p>
                                    </div>
                                    <div className="inline-btn">
                                        {/* <button classname="btn-start"><a href='./loginform'>Get Started</a></button> */}
                                        <button classname="btn-start">Learn More</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <div className="row m-0">

                                    <div className='backgroundimg'>
                                        <img className='backgroundImg rounded-img' src='https://media.licdn.com/dms/image/D4E12AQHcWdFPUeoQ7g/article-cover_image-shrink_720_1280/0/1681172374235?e=2147483647&v=beta&t=ox3OZ9izZN5aV1cQoJh3pIC7LULq_6BT6pK23Fuf9As' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="container">
                        <div className="row mx-0 mb-4 mt-3">
                            <div className="bright-text text-enter">
                                <h5>About Us</h5>
                                <p>Learn about our journey and our goal to make digital connections better for everyone.</p>
                            </div>
                            <div className="col-md-5 d-flex align-items-center">
                                <div className="row my-3">
                                    <div className="About-title">
                                        <h5> Our story started with a love for new ideas and a dream to change how people connect online.</h5>
                                        <p> At Bright Feture coin we're leading with FUTURE Coin, creating new ways to use technology that make money and digital life better for everyone around the world. From the beginning, our mission has been to innovate and provide solutions that help individuals and businesses succeed in a digital age. We are passionate about using the latest technology to create meaningful connections and make the digital world better for everyone.
                                        </p>
                                    </div>
                                    {/* <div className="inlines-btn">
                                        <button classname="btn-start">Read More</button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row m-0">
                                    <div className="card px-0">
                                        <img className="about-img" src={aboutimg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <section id="stats" className="stats section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6">
                                        <div className="stats-item">
                                            <span><i className="fas fa-users icon" /></span>
                                            <div className="stats-text">
                                                <span className="counter">232</span>
                                                <p>Clients</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="stats-item">
                                            <span><i className="fas fa-project-diagram icon" /></span>
                                            <div className="stats-text">
                                                <span className="counter">521</span>
                                                <p>Projects</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="stats-item">
                                            <span><i className="fas fa-clock icon" /></span>
                                            <div className="stats-text">
                                                <span className="counter">1453</span>
                                                <p>Hours</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6">
                                        <div className="stats-item">
                                            <span><i className="fas fa-user-tie icon" /></span>
                                            <div className="stats-text">
                                                <span className="counter">32</span>
                                                <p>Workers</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="row mx-0 mb-4 mt-3 our-product-mange">
                            <div className="bright-text text-enter mb-4">
                                <h5>Our Products</h5>
                                <p className='mb-0'>A revolution has been taking place all around the world.</p>
                            </div>
                            <SwiperComponent />
                        </div>
                        {/* <div className="row mb-4">
                            <div className="bright-text text-enter">
                                <h5>Services</h5>
                                <p>A revolution has been taking place all around the world.</p>
                            </div>
                            <SwiperComponent />
                        </div> */}
                        <div className="row mb-4">
                            <div className="bright-text text-enter">
                                <h5>Client Testimonials</h5>
                                <p>Learn about how our solutions can benefit your business and make a positive impact today.</p>
                            </div>
                            <Client/>
                        </div>
                        <div className="row mb-4">
                            <div className="bright-text text-enter">
                                <h5>Why Choose Us</h5>
                                <p>Learn about how our solutions can benefit your business and make a positive impact today.</p>
                            </div>
                            <Choose/>
                        </div>
                    </div>
                </div>
                
            </div>
            {/* </div > */}
        </>
    )
}
export default Home;