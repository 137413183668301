import React from 'react';
import './chatbot.css';
const Chatbot = () => {
    return (
        <>
        <div className='container-fluid'>
            {/* <div className='container'> */}
                <div className='row m-0'>
            <div className="area-chatbot">
            <div className="context-chatbot">
                <h1>Comeing Soon.......... </h1>
            </div>
            <div className='background-img'>
                    <img className='backgroundImg' src='https://i.ytimg.com/vi/Odf0fCChr_k/maxresdefault.jpg'/>
                </div>
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
                </div>
            </div>
        {/* </div> */}

        </>
    );
}
export default Chatbot;