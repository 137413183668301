import React, { useState, useEffect } from 'react';
import './ottplatform.css'
// import axios from 'axios'
const Ottplatform = () => {
    return (
        <>
            {/* <div className="container-fluid px-0">
                <div className="container">
                    <div className="row m-0">
                        <section className="top-bar">
                            <div className="left-content">
                                <h2 className="title">M4You</h2>
                                <ul className="navigation">
                                    <li><a href="#">Home</a></li>
                                    <li><a className="active" href="#">Movies</a></li>
                                    <li><a href="#">Theaters</a></li>
                                    <li><a href="#">News</a></li>
                                </ul>
                            </div>
                        </section>
                        <section className="main-container">
                            <div className="sidebar">
                                <form action="#">
                                    <div className="sidebar-groups">
                                        <h3 className="sg-title">Categories</h3>
                                        <input type="checkbox" id="adventure" name="adventure" defaultValue="adventure" />
                                        <label htmlFor="adventure">Adventure</label><br />
                                        <input type="checkbox" id="action" name="action" defaultValue="action" />
                                        <label htmlFor="action">Action</label><br />
                                        <input type="checkbox" id="animation" name="animation" defaultValue="animation" />
                                        <label htmlFor="animation">Animation</label><br />
                                        <input type="checkbox" id="comedy" name="comedy" defaultValue="comedy" />
                                        <label htmlFor="comedy">Comedy</label><br />
                                        <input type="checkbox" id="science" name="science" defaultValue="science" />
                                        <label htmlFor="science">Science Fiction</label><br />
                                        <input type="checkbox" id="thriller" name="thriller" defaultValue="thriller" />
                                        <label htmlFor="thriller">Thriller</label><br />
                                        <input type="checkbox" id="history" name="history" defaultValue="history" />
                                        <label htmlFor="history">History</label><br />
                                        <input type="checkbox" id="documentary" name="documentary" defaultValue="documentary" />
                                        <label htmlFor="documentary">Documentary</label><br />
                                        <input type="checkbox" id="fantasy" name="fantasy" defaultValue="fantasy" />
                                        <label htmlFor="fantasy">Fantasy</label><br />
                                    </div>
                                    <div className="sidebar-groups">
                                        <h3 className="sg-title">Language</h3>
                                        <input type="checkbox" id="english" name="english" defaultValue="english" />
                                        <label htmlFor="english">English</label><br />
                                        <input type="checkbox" id="spanish" name="spanish" defaultValue="spanish" />
                                        <label htmlFor="spanish">Spanish</label><br />
                                        <input type="checkbox" id="hindi" name="hindi" defaultValue="hindi" />
                                        <label htmlFor="hindi">Hindi</label><br />
                                    </div>
                                    <div className="sidebar-groups">
                                        <h3 className="sg-title">Time</h3>
                                        <input type="radio" id="morning" name="time" defaultValue="morning" />
                                        <label htmlFor="morning">Morning</label><br />
                                        <input type="radio" id="night" name="time" defaultValue="night" />
                                        <label htmlFor="night">Night</label><br />
                                    </div>
                                    <div className="sidebar-groups">
                                        <a href="#" className="btn-l btn">Apply Filters</a>
                                    </div>
                                </form>
                            </div>
                            <div className="movies-container">
                                <div className="upcoming-img-box">
                                    <img src="https://www.vanas.ca/images/blog/vfx-visual-effects-vanas.jpg" className="upcoming-movie" />
                                    <p className="upcoming-title">Upcoming Movie</p>
                                    <div className="buttons">
                                        <a href="#" className="btn">Book Now</a>
                                        <a href="#" className="btn-alt btn">Play Trailer</a>
                                    </div>
                                </div>
                                <div className="current-movies">
                                    <div className="current-movie">
                                        <div className="cm-img-box mb-0">
                                            <img src="https://i.ytimg.com/vi/aJJrkyHas78/maxresdefault.jpg" className="movie-imgs"/>
                                        </div>
                                        <div className="moive-content">
                                        <h3 className="movie-title">Jurassic World</h3>
                                        <p className="screen-name">Screen : Platinum</p>
                                        <div className="booking">
                                            <h2 className="price">$15.00</h2>
                                            <a href="#" className="btn">Buy Tickets</a>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="current-movie">
                                        <div className="cm-img-box mb-0">
                                            <img src="https://i.ytimg.com/vi/2zXxlo5EGUE/maxresdefault.jpg" className="movie-imgs" />
                                        </div>
                                        <div className="moive-content">
                                        <h3 className="movie-title">Vikram</h3>
                                        <p className="screen-name">Screen : Gold</p>
                                        <div className="booking">
                                            <h2 className="price">$10.00</h2>
                                            <a href="#" className="btn">Buy Tickets</a>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="current-movie">
                                        <div className="cm-img-box mb-0">
                                            <img src="https://i.ytimg.com/vi/pXm6TWz5MO4/maxresdefault.jpg" className="movie-imgs" />
                                        </div>
                                        <div className="moive-content">
                                        <h3 className="movie-title">Firestarter</h3>
                                        <p className="screen-name">Screen : Silver</p>
                                        <div className="booking">
                                            <h2 className="price">$5.00</h2>
                                            <a href="#" className="btn">Buy Tickets</a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div> */}
             <div className='container-fluid'>
                <div className='row m-0'>
            <div className="area-chatbot">
            <div className="context-chatbot">
                <h1>Comeing Soon.......... </h1>
            </div>
            <div className='background-img'>
                    <img className='backgroundImg' src='https://www.vanas.ca/images/blog/vfx-visual-effects-vanas.jpg'/>
                </div>
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            </div>
        </div>
        </>
    )
}
export default Ottplatform;