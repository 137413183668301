import "./header.module.css";
import bright from "../../assets/bright-logo.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <>
      {/* <div className="container-fluid px-0 main-header">
        <div className='container px-0'>
          <header className="header-bar d-flex justify-content-between py-2 ">
            <div className="project-logo">
              <img src={bright} />
            </div>
            <ul className="nav nav-pills">
              <li className="nav-item"><Link to="/" className="nav-link active" aria-current="page">Home</Link></li>
              <li className="nav-item"><Link to="/" className="nav-link">About</Link></li>
              <div class="dropdown">
                <Link class="dropdown-setup dropdown-toggle" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Our products
                </Link>
                <ul class="dropdown-menu">
                  <li><Link class="dropdown-item" to="./Ecommarce">e-Commerce</Link></li>
                  <li><Link class="dropdown-item" to="./socialmedia">Social Media</Link></li>
                  <li><Link class="dropdown-item" to="./voiceassistant">Voice Assistant</Link></li>
                  <li><Link class="dropdown-item" to="./gameing">Gameing</Link></li>
                  <li><Link class="dropdown-item" to="./chatbot">Chat bot</Link></li>
                  <li><Link class="dropdown-item" to="./ottplatform">Ott platform</Link></li>
                </ul>
              </div>
              <li className="nav-item"><Link to="/" className="nav-link">Services</Link></li>
              <li className="nav-item"><Link to="/" className="nav-link">Pricing</Link></li>
            </ul>
            <button className="btn-start btn-set"><Link to='./loginform'>Get Started</Link></button>
          </header>

        </div>
      </div> */}
      <div className="container-fluid px-0 main-header">
        <div className="container px-0">
          <nav className="navbar navbar-expand-lg navbar-dark">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">
                <div className="project-logo">
                  <img src={bright} />
                </div>
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <label for="check" class="checkbtn ms-0 text-white">
                  <i class="fas fa-bars"></i>
                </label>
              </button>
              <div className=" collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto ">
                  <li className="nav-item">
                    <Link
                      className="nav-link  active"
                      aria-current="page"
                      to="/"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/">
                      About
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link  dropdown-toggle"
                      to="/"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Our products
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <Link className="dropdown-item" to="./Ecommarce">
                          E-Commerce
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="./socialmedia">
                          Social Media
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="./voiceassistant">
                          Voice Assistant
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="./gameing">
                          Gameing
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="./chatbot">
                          Chat bot
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="./ottplatform">
                          Ott platform
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="./astrology">
                          Astrology
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/">
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link " to="/">
                      Pricing
                    </Link>
                  </li>
                  <button className="btn-start btn-set">
                    <Link to="/">Get Started</Link>
                  </button>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
export default Header;
